import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import { Table, Typography } from 'antd';

import BarcodeRow from './BarcodeRow';
import { columns } from './columns';
import { blacklistedStatuses } from '../../Constants';
import { addKey } from '../shared/utils';

const { Title } = Typography

const canReclaim = (stateId) =>
    stateId === 'move-package-from-box' ||
    stateId === 'request-lockdown' ||
    stateId === 'package-moved-from-box-v2' ||
    stateId === 'package-moved-from-box' ||
    stateId === 'lockdown-notification-timeout' ||
    stateId === 'BoundaryEvent_0h6szie' ||
    stateId === 'send-lockdown-notification' ||
    stateId === 'end-lockdown-notification' ||
    stateId === 'EndEvent_038psog';

const showDeliveryCode = (stateId) =>
    stateId === 'order-placed-in-box'

const showPickupCode = (stateId) =>
    stateId === 'collection-placed-in-box' ||
    stateId === 'staff-collection-reminder'

const ShippingHistory = ({ data, loading, delivered }) => {

    const { history = [], deliveryCode, deliveryCodeUrl, pickupCode, pickupCodeUrl, reclaimCode, reclaimCodeUrl, relatedShipmentBusinessKey, currentState } = data

    const stateId = currentState?.stateId

    const dataSource = history
        .toReversed()
        .filter(row => !blacklistedStatuses.includes(row.topic))
        .map(addKey)

    const renderBarCode = () => {
        if (delivered) return null

        if (showDeliveryCode(stateId) && deliveryCode)
            return (
                <BarcodeRow
                    barCodeVal={deliveryCode}
                    qrCodeVal={deliveryCodeUrl}
                    out={false}
                />
            )

        if (showPickupCode(stateId) && pickupCode)
            return (
                <BarcodeRow
                    barCodeVal={pickupCode}
                    qrCodeVal={pickupCodeUrl}
                    out={true}
                />
            )

        if (canReclaim(stateId) && reclaimCode)
            return (
                <BarcodeRow
                    barCodeVal={reclaimCode}
                    qrCodeVal={reclaimCodeUrl}
                    out={true}
                />
            )
    }

    return (
        <>
            <Title level={3}><FormattedMessage id="AboutDelivery.ShippingHistory" /></Title>

            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                showHeader={false}
                loading={loading}
                scroll={{ y: 350, x: 'max-content' }}
                style={{ marginBottom: 24 }}
            />

            {renderBarCode()}

            {relatedShipmentBusinessKey && (
                <div>
                    <span>Tengd sending: </span>
                    <Link to={`/about-delivery/${relatedShipmentBusinessKey}`}>{relatedShipmentBusinessKey}</Link>
                </div>
            )}
        </>
    )
}

export default ShippingHistory;
